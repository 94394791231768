import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
// import store from '@/store'
// import { getToken } from '@/utils/auth'
//import errorCode from '@/utils/errorCode'
let baseURL =
  process.env.NODE_ENV == "development"
    ? process.env.VUE_APP_API_DEV
    : process.env.VUE_APP_API_PROD;
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const Axios = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL,
  // 超时
  timeout: 10000,
});
// request拦截器
Axios.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem("mytoken")) {
      config.headers.Authorization = sessionStorage.getItem("mytoken");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
Axios.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    //const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // store.dispatch('LogOut').then(() => {
          //   location.href = '/index';
          // })
        })
        .catch(() => {});
    } else if (code === 500) {
      Message({
        //message: msg,
        type: "error",
      });
      //return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Notification.error({
        //title: msg
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    var message;
    let { response } = error;
    if (response.status === 422) {
      message = response.data.errors[Object.keys( response.data.errors)[0]][0];
    } else{
      message =  response.data.message
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default Axios;
