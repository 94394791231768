<template>
  <div class="dialog">
    <el-dialog
      title="全局设置"
      :visible="showGlobalSet"
      :append-to-body="true"
      width="500px"
      @close="switchShow(false)"
      custom-class="globalSetDialog"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <el-tabs v-model="activeName" type="card" :before-leave="beforeLeave">
          <el-tab-pane label="默认渠道" name="default"
            ><span class="setLabel"
              >产品渠道ID=0x00000000时，APP上需要按以下配置显示。</span
            >
          </el-tab-pane>
          <el-tab-pane label="非法产品渠道ID" name="not_found"
            ><span class="setLabel"
              >当APP使用“云端不存在的渠道ID”查询时，服务端除返回常规错误外，还需支持自定义返回的型号、厂家2个字段的文字表述内容。</span
            ></el-tab-pane
          >
          <el-tab-pane label="空白产品渠道ID" name="empty"
            ><span class="setLabel"
              >当APP使用“空白ID”查询时，服务端除返回常规错误外，还需支持自定义返回的型号、厂家2个字段的文字表述内容。</span
            ></el-tab-pane
          >
        </el-tabs>
        <el-form label-width="100px" :model="formDate">
          <el-form-item label="商家名称：">
            <el-input v-model="formDate.business_name"></el-input>
          </el-form-item>
          <el-form-item label="产品型号：">
            <el-input v-model="formDate.model"></el-input>
            <span style="color: #959595">注：留空时APP将显示本地型号</span>
          </el-form-item>
          
        </el-form>
        <span class="dialog-footer">
          <el-button @click="switchShow(false)">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </el-main>
    </el-dialog>
  </div>
</template>

<script>
import { getGroupSetRequest, editGroupSetRequest } from "@api/repository";
export default {
  props: ["showGlobalSet"],
  data() {
    return {
      activeName: "default",
      formDate: {},
      defaultData: {},
      notFoundData: {},
      emptyData: {},
      loading: false,
    };
  },
  components: {},
  mounted() {},
  watch: {
    showGlobalSet() {
      this.init();
    },
    activeName: {
      handler() {
        this.loading = true;
        getGroupSetRequest()
          .then((res) => {
            this.loading = false;
            res.forEach((item) => {
              if (item.name === "default") {
                this.defaultData = item;
              } else if (item.name === "not_found") {
                this.notFoundData = item;
              } else if (item.name === "empty") {
                this.emptyData = item;
              }
            });
            if (this.activeName === "default") {
              this.formDate = JSON.parse(JSON.stringify(this.defaultData));
            } else if (this.activeName === "not_found") {
              this.formDate = JSON.parse(JSON.stringify(this.notFoundData));
            } else if (this.activeName === "empty") {
              this.formDate = JSON.parse(JSON.stringify(this.emptyData));
            }
          })
          .catch(() => (this.loading = false));
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    beforeLeave() {
      if (this.showGlobalSet) {
        if (this.activeName === "default") {
          if (
            this.formDate.business_name !== this.defaultData.business_name ||
            this.formDate.model !== this.defaultData.model
          ) {
            this.beforeLeaveSubmit(this.formDate);
          }
        } else if (this.activeName === "not_found") {
          if (
            this.formDate.business_name !== this.notFoundData.business_name ||
            this.formDate.model !== this.notFoundData.model
          ) {
            this.beforeLeaveSubmit(this.formDate);
          }
        } else if (this.activeName === "empty") {
          if (
            this.formDate.business_name !== this.emptyData.business_name ||
            this.formDate.model !== this.emptyData.model
          ) {
            this.beforeLeaveSubmit(this.formDate);
          }
        }
      }
    },
    beforeLeaveSubmit(parms) {
      this.$confirm("您修改了参数, 是否要提交修改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          editGroupSetRequest(parms.name, {
            business_name: parms.business_name,
            model: parms.model,
          })
            .then(() => {
              this.loading = false;
              this.$message.success("修改设置成功");
            })
            .catch(() => (this.loading = false));
        })
        .catch(() => {});
    },
    init() {
      this.activeName = "default";
      getGroupSetRequest().then((res) => {
        res.forEach((item) => {
          if (item.name === "default") {
            this.defaultData = item;
          } else if (item.name === "not_found") {
            this.notFoundData = item;
          } else if (item.name === "empty") {
            this.emptyData = item;
          }
        });
        if (this.activeName === "default") {
          this.formDate = JSON.parse(JSON.stringify(this.defaultData));
        } else if (this.activeName === "not_found") {
          this.formDate = JSON.parse(JSON.stringify(this.notFoundData));
        } else if (this.activeName === "empty") {
          this.formDate = JSON.parse(JSON.stringify(this.emptyData));
        }
      });
    },
    switchShow(param) {
      this.$emit("update:showGlobalSet", param);
    },
    onSubmit() {
      this.loading = true;
      editGroupSetRequest(this.formDate.name, {
        business_name: this.formDate.business_name,
        model: this.formDate.model,
      })
        .then(() => {
          this.loading = false;
          this.switchShow(false);
          this.$message.success("修改设置成功");
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.globalSetDialog {
  .setLabel {
    color: #8f8f8f;
    display: block;
    font-size: 12px;
    margin-bottom: 20px;
  }
  .el-main {
    padding: 0px 20px !important;
    .dialog-footer {
      float: right;
    }
  }
}
</style>