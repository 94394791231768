import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@assets/css/element-variables.scss'
import router from "./router";
import App from "./App.vue";
import store from "./store";
import http from "@api/index";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$http = http;



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
