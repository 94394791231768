<template>
  <el-main v-loading="loading">
    <div class="main-header">
      <div class="left">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{ routeParam.name }}</el-breadcrumb-item>
          <el-breadcrumb-item
            >产品渠道 · {{ channelsNumber }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="right">
        <div class="search">
          <el-input
            placeholder="搜索ID、商家名、产品型号、备注"
            size="small"
            v-model="searchValue"
            @keyup.enter.native="search"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            @click="search"
            class="searchButton"
            plain
            >搜索</el-button
          >
        </div>

        <el-button type="primary" size="small" @click="handleChannel('add')"
          >添加</el-button
        >
      </div>
    </div>
    <div class="main-table">
      <el-table
        ref="channelsTable"
        :data="tableData"
        style="width: 100%"
        border
        :row-style="{ height: '23px' }"
        :cell-style="{ padding: '3px' }"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sort"
        :max-height="tableHeight"
      >
        <el-table-column prop="channel_id" label="ID" width="130">
        </el-table-column>
        <el-table-column
          prop="model"
          label="产品型号"
          show-overflow-tooltip
          sortable="custom"
        >
        </el-table-column>
        <el-table-column
          prop="business_name"
          label="商家名"
          show-overflow-tooltip
          sortable="custom"
        >
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="更新"
          sortable="custom"
          width="340"
        >
          <template slot-scope="scope">
            {{ scope.row.updated_by_user }} 【{{ scope.row.updated_at }}】更新
          </template>
        </el-table-column>
        <el-table-column align="left" fixed="right" label="操作" width="170">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="handleChannel('delete', scope.row)"
              >删除</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="handleChannel('edit', scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="main-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.page"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="pagination.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <add-or-edit-channel
      :visible.sync="isShowAddOrEditChannel"
      :openDialogParms="openDialogParms"
      :handleChannelType="handleChannelType"
      :currentGroupId="currentGroupId"
      :currentGroupName="currentGroupName"
      @refreshTable="getChannelList(currentGroupId, pagination)"
    ></add-or-edit-channel>
  </el-main>
</template>

<script>
import AddOrEditChannel from "@components/AddOrEditChannel.vue";
import {
  getChannelsListRequest,
  searchChannelsListRequest,
  sortChannelsListRequest,
} from "@api/repository";

export default {
  data() {
    return {
      currentPage: 4,
      isShowAddOrEditChannel: false,
      openDialogParms: {},
      handleChannelType: "",
      tableData: [],
      currentGroupId: "",
      currentGroupName: "",
      channelsNumber: 0,
      pagination: {},
      searchValue: "",
      tableHeight: 0,
      loading: false,
    };
  },
  components: { AddOrEditChannel },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 190;
    });
  },
  watch: {
    routeParam: {
      handler(val) {
        this.currentGroupId = val.id;
        this.currentGroupName = val.name;

        this.getChannelList(val.id, { size: 20, page: 1 });
      },
      immediate: true,
    },
  },
  computed: {
    routeParam() {
      return this.$route.query;
    },
  },
  methods: {
    switchShow(param) {
      this.isShowAddOrEditChannel = param;
    },
    handleChannel(type, param) {
      this.openDialogParms = param;
      this.handleChannelType = type;
      this.switchShow(true);
    },
    handleSizeChange(val) {
      this.$refs.channelsTable.clearSort();
      this.pagination.size = val;
      this.getChannelList(this.currentGroupId, this.pagination);
    },
    handleCurrentChange(val) {
      this.$refs.channelsTable.clearSort();
      this.pagination.page = val;
      this.getChannelList(this.currentGroupId, this.pagination);
    },
    getChannelList(id, paginationParams) {
      this.loading = true;
      getChannelsListRequest(id, paginationParams).then((res) => {
        this.loading = false;
        this.tableData = res.data;
        this.channelsNumber = res.meta.total;
        this.pagination.page = res.meta.current;
        this.pagination.size = res.meta.size;
        this.pagination.total = res.meta.total;
      }).catch(() => (this.loading = false));
    },
    search() {
      this.loading = true;
      searchChannelsListRequest(
        this.currentGroupId,
        { size: 20, page: 1 },
        this.searchValue
      ).then((res) => {
        this.loading = false;
        this.tableData = res.data;
        this.pagination.page = res.meta.current;
        this.pagination.size = res.meta.size;
        this.pagination.total = res.meta.total;
      }).catch(() => (this.loading = false));
    },
    sort(params) {
      const temp = {
        sort: params.prop,
        order: params.order === "descending" ? "desc" : "asc",
      };
      this.loading = true;
      sortChannelsListRequest(
        this.currentGroupId,
        { size: 20, page: 1 },
        this.searchValue,
        temp
      ).then((res) => {
        this.loading = false;
        this.tableData = res.data;
        this.pagination.page = res.meta.current;
        this.pagination.size = res.meta.size;
        this.pagination.total = res.meta.total;
      }).catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  padding: 5px 20px;
  .main-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      justify-content: space-between;
      width: 430px;
      .search {
        display: flex;
        width: 80%;
        .searchButton {
          margin-left: 5px;
        }
      }
    }
  }
  /deep/ .el-table {
    th {
      padding: 5px;
    }
    th.el-table__cell {
      background-color: #f5f7fa !important;
    }
  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>