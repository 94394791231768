<template>
  <div class="dialog">
    <el-dialog
      :title="title"
      :visible="visible"
      v-if="visible"
      :append-to-body="true"
      @close="switchShow(false)"
      width="500px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <el-form
          label-width="100px"
          :model="formDate"
          :rules="rules"
          ref="addOrEditForm"
        >
          <el-form-item label="产品渠道ID" v-if="isEdit" prop="channel_id">
            <span class="fixedInput">0x</span>
            <el-input
              maxlength="8"
              class="channel_id_input"
              v-model="formDate.channel_id"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="商家名称" prop="business_name">
            <el-input v-model="formDate.business_name"></el-input>
          </el-form-item>
          <el-form-item label="产品型号" prop="model">
            <el-input v-model="formDate.model"></el-input>
          </el-form-item>
          <el-form-item label="所属组">
            <el-select v-model="addGroupId" placeholder="请选择">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formDate.remark"></el-input>
          </el-form-item>
        </el-form>
        <span class="dialog-footer">
          <el-button @click="switchShow(false)">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </el-main>
    </el-dialog>
    <el-dialog
      :title="sucessTitle"
      :visible="sucessVisible"
      :append-to-body="true"
      :show-close="false"
      @close="closeSucess"
      width="30%"
      custom-class="sucessDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-main>
        <ul>
          <li>
            <div class="label">产品渠道ID</div>
            <div class="value">
              <span>{{ sucessFormDate.channel_id }}</span>
              <el-button size="medium" type="text" @click="copyId"
                >复制</el-button
              >
            </div>
          </li>
          <li>
            <div class="label">商家名称</div>
            <div class="value" :title="sucessFormDate.business_name">
              {{ sucessFormDate.business_name }}
            </div>
          </li>
          <li>
            <div class="label">产品型号</div>
            <div class="value" :title="sucessFormDate.model">
              {{ sucessFormDate.model }}
            </div>
          </li>
          <li>
            <div class="label">所属组</div>
            <div class="value" :title="sucessFormDate.group_name">
              {{ sucessFormDate.group_name }}
            </div>
          </li>
          <li>
            <div class="label">备注</div>
            <div class="value" :title="sucessFormDate.remark">
              {{ sucessFormDate.remark }}
            </div>
          </li>
        </ul>
      </el-main>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeSucess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="删除产品渠道"
      :visible="deleteVisible"
      :append-to-body="true"
      width="400px"
      @close="deleteVisible = false"
      custom-class="deleteDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <span v-if="deleteVisible"
          >确定要删除ID为【{{
            openDialogParms.channel_id
          }}】的产品渠道吗？</span
        >
        <el-input
          placeholder="请输入你要删除的产品渠道ID"
          v-model="deleteChannelId"
        ></el-input>
        <span class="dialog-footer">
          <el-button @click="deleteVisible = false">取 消</el-button>
          <el-button type="primary" @click="deleteChannel">确 定</el-button>
        </span>
      </el-main>
    </el-dialog>
  </div>
</template>

<script>
import {
  addChannelRequest,
  //getChannelDetailRequest,
  editChannelRequest,
  deleteChannelRequest,
} from "@api/repository";
import { mapState, mapMutations } from "vuex";
import Clipboard from "clipboard";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    openDialogParms: {
      type: Object,
    },
    handleChannelType: {
      type: String,
    },
    currentGroupId: {
      type: [String, Number],
    },
    currentGroupName: {
      type: String,
    },
  },
  data() {
    return {
      formDate: {},
      sucessVisible: false,
      deleteVisible: false,
      sucessFormDate: {},
      deleteChannelId: "",
      addGroupId: "",
      loading: false,
      rules: {
        channel_id: [
          {
            required: true,
            message: "请输入0-9、A-F的8位字符",
            pattern: /^[0-9a-fA-F]{8}$/,
            trigger: "change",
          },
        ],
        business_name: [
          {
            required: true,
            message: "请输入商家名称",
            trigger: "change",
          },
          {
            min: 0,
            max: 50,
            message: "长度在 0 到 50 个字符",
            trigger: "change",
          },
        ],
        model: [
          {
            required: true,
            message: "请输入产品型号",
            trigger: "change",
          },
          {
            min: 0,
            max: 50,
            message: "长度在 0 到 50 个字符",
            trigger: "change",
          },
        ],
        remark: [
          {
            min: 0,
            max: 200,
            message: "长度在 0 到 200 个字符",
            trigger: "change",
          },
        ],
      },
    };
  },
  components: {},
  mounted() {},
  watch: {
    visible() {
      if (this.handleChannelType === "edit") {
        this.formDate = JSON.parse(JSON.stringify(this.openDialogParms));
        this.addGroupId = this.formDate.group_id;
        this.formDate.channel_id = this.formDate.channel_id.slice(2);
      } else if (this.handleChannelType === "add") {
        this.formDate = {};
        this.formDate.channel_id = "";
        this.formDate.group_id =
          this.currentGroupId === "" ? "" : Number(this.currentGroupId);
        this.addGroupId =
          this.currentGroupId === "" ? "" : Number(this.currentGroupId);
      } else if (this.handleChannelType === "delete") {
        this.deleteChannelId = "";
        this.deleteVisible = true;
        this.switchShow(false);
      }
    },
    addGroupId(val) {
      this.formDate.group_id = val;
    },
  },
  computed: {
    ...mapState(["groupList"]),
    isEdit() {
      return this.handleChannelType === "edit" ? true : false;
    },
    sucessTitle() {
      return this.isEdit ? "编辑成功" : "添加成功";
    },
    title() {
      return this.isEdit ? "编辑产品渠道" : "添加产品渠道";
    },
  },
  methods: {
    ...mapMutations(["setChangeIndex", "setChangeId"]),
    switchShow(param) {
      this.$emit("update:visible", param);
    },
    onSubmit() {
      this.$refs.addOrEditForm.validate((valid) => {
        //未验证通过则直接return
        if (!valid) return;
        this.loading = true;
        if (this.isEdit) {
          const tempObj = {
            channel_id: "0x" + this.formDate.channel_id.toLocaleUpperCase(),
            business_name: this.formDate.business_name,
            model: this.formDate.model,
            group_id: this.formDate.group_id,
            remark: this.formDate.remark,
          };
          editChannelRequest(this.formDate.id, tempObj)
            .then((res) => {
              this.loading = false;
              this.sucessFormDate = res;
              this.sucessVisible = true;
            })
            .catch(() => (this.loading = false));
        } else {
          const tempObj = {
            business_name: this.formDate.business_name,
            model: this.formDate.model,
            group_id: this.formDate.group_id,
            remark: this.formDate.remark,
          };
          addChannelRequest(tempObj)
            .then((res) => {
              this.loading = false;
              this.sucessFormDate = res;
              this.sucessVisible = true;
            })
            .catch(() => (this.loading = false));
        }
      });
    },
    deleteChannel() {
      if (this.deleteChannelId === this.openDialogParms.channel_id) {
        this.loading = true;
        deleteChannelRequest(this.openDialogParms.id)
          .then(() => {
            this.loading = false;
            this.$emit("refreshTable", this.currentGroupId);
            this.deleteVisible = false;
            this.$message.success("删除成功");
          })
          .catch(() => (this.loading = false));
      } else {
        this.$message.error("您输入的ID与实际ID不匹配，请重新输入");
        this.deleteChannelId = "";
      }
    },
    closeSucess() {
      if (this.sucessFormDate.group_name === this.currentGroupName) {
        this.$emit("refreshTable", this.currentGroupId);
      } else {
        this.setChangeIndex(this.sucessFormDate.group_name);
        this.setChangeId(this.sucessFormDate.group_id);
      }
      this.sucessVisible = false;
      this.switchShow(false);
    },
    copyId() {
      Clipboard.copy(this.sucessFormDate.channel_id);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sucessDialog {
  li {
    display: flex;
    height: 30px;
    align-items: center;
    .label {
      width: 30%;
      text-align: right;
    }
    .value {
      width: 60%;
      text-align: left;
      margin-left: 5%;
      overflow: hidden; /*设置超出的部分进行影藏*/
      text-overflow: ellipsis; /*设置超出部分使用省略号*/
      white-space: nowrap;
      .el-button {
        margin-left: 5px;
      }
    }
  }
}
/deep/ .channel_id_input {
  input {
    text-transform: uppercase;
    padding-left: 26px;
  }
}
.fixedInput {
  color: #9d9d9d;
  position: absolute;
  z-index: 999;
  left: 10px;
}
.el-select {
  width: 100%;
}
/deep/ .el-dialog__body {
  padding: 0px 20px !important;
  .dialog-footer {
    float: right;
  }
}
/deep/ .deleteDialog {
  .el-dialog__body {
    padding: 15px 20px !important;
  }
  .dialog-footer {
    .el-input {
      margin-bottom: 20px;
    }
  }
}
.deleteDialog {
  .el-main {
    padding: 7px;
  }
  .el-input {
    margin: 15px 0;
  }
}
</style>