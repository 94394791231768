<template>
  <div class="login">
    <div class="login-form-wrap">
      <el-card class="box-card login-cont edit-input">
        <div class="card-header">
          <h2>Starfun 产品渠道管理系统</h2>
          <div class="version">v{{ config.version }}</div>
        </div>

        <el-form :model="form" ref="form" :rules="rules" class="my-5">
          <el-form-item prop="username">
            <i class="el-icon-user append-icon"></i>
            <el-input
              v-model="form.username"
              name="username"
              class="font-16"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <i class="el-icon-unlock append-icon"></i>
            <el-input
              type="password"
              show-password
              class="font-16"
              v-model="form.password"
              @keyup.enter.native="login"
              placeholder="密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="card-footer">
          <el-button
            type="primary"
            class="login-btn"
            @click="login"
            :loading="loading"
            :disabled="!form.username || !form.password"
            >登 录</el-button
          >
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import navLogo from "@assets/img/logo.svg";
import { loginRequest } from "@api/repository";
import { mapMutations } from "vuex";
import config from "../../package.json";
export default {
  components: {},
  // 定义属性
  data() {
    return {
      navLogo,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
      },
      loading: false,
      config,
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        let q = val.query;
        if (q && Object.keys(q).length && q.username) {
          this.form.username = q.username;
        }
      },
    },
  },
  // 方法集合
  methods: {
    ...mapMutations(["setUser"]),
    login() {
      this.$refs.form.validate((valid) => {
        //未验证通过则直接return
        if (!valid) return;
        loginRequest(this.form).then((response) => {
          //从res的元数据中得到返回状态
          if (response.access_token) {
            // 1，将登录成功之后的token，保存到客户端的sessionStorage(会话存储)中
            // 1.1 项目中除了登录之外的其他API接口，必须在登录之后才能访问
            // 1.2 token只应在当前网站打开期间生效，所以token保存在sessionStorage中

            // 2，通过编程式导航跳转到后台主页。路由地址是 /home
            window.sessionStorage.setItem(
              "mytoken",
              "Bearer " + response.access_token
            );
            window.sessionStorage.setItem("username", this.form.username);
            this.setUser(this.form.username);
            this.$router.push("/home");
            return this.$message.success("登录成功");
          }
        });
      });
    },
    forgetPwd() {
      this.$router.push({
        name: "Forget_Pwd",
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='scss' scoped>
.login {
  height: 100vh;
      display: flex;
    justify-content: center;
    align-items: center;
  .login-title {
    display: flex;
    align-items: center;
    color: #6c757d;
    padding: 3vh;
  }
  .login-cont {
    border-radius: 1rem;
    margin: 0 auto;
    width: 500px;
    max-width: 90vw;
    padding: 1.5rem;
    .version{
      margin-top: 10px;
    }
    .card-footer {
      display: flex;
      justify-content: center;
      .login-btn {
        font-size: 20px;
        border-radius: 15px;
        padding: 16px;
        width: 50%;
      }
    }

    .card-header {
      display: flex;
      padding: 0 0 5vh 0;
      align-items: center;
      flex-direction: column;
    }
  }
}
.login-form-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-image: url("~@/assets/img/ic-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /deep/ .el-input input {
    padding-left: 40px;
    border-width: 0 0 2px 0;
    border-radius: 0;
  }
  .append-icon {
    position: absolute;
    top: 6px;
    left: 0;
    font-size: 25px;
    z-index: 1;
    color: #399;
  }
  .is-error .append-icon {
    color: #f56c6c;
  }
}
</style>
