import error from '@views/error'
import home from '@views/home'
import login from '@views/login'
import ProductChannelTable from '@components/ProductChannelTable'

export default [
  {
    path: '/',
    name:'Home',
    component:home,
    children: [
      {
        path: 'productChannelTable',
        component: ProductChannelTable,
        name:'productChannelTable'
      }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    component: error
  },
  {
    path: '/login',
    name:'Login',
    component:login
  },
  {
    path: '*',
    redirect: '/'
  }
]