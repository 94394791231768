import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
    routes,
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
//挂载路由导航守卫
router.beforeEach((to,from,next)=>{
    // to 将要访问的路径
    // from 代表从哪一个路径跳转而来
    // next 是一个函数，表示放行
    // next() 放行 next('/login') 跳转
    if (to.path === '/login') return next();
    //获取token
    const tokenStr = window.sessionStorage.getItem('mytoken')
    //没有token，强制跳转登录页
    if(!tokenStr) return next('/login')
    // 放行
    next()
  });
export default router