<template>
  <div class="dialog">
    <el-dialog
      title="添加群组"
      :visible.sync="addDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <el-input
          placeholder="请输入群组名称"
          v-model="name"
          size="small"
        ></el-input>
        <span class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addGroup">确 定</el-button>
        </span>
      </el-main>
    </el-dialog>
    <el-dialog
      title="重命名群组"
      :visible.sync="renameDialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <el-input
          v-model="name"
          placeholder="请输入群组名称"
          size="small"
        ></el-input>
        <span class="dialog-footer">
          <el-button @click="renameDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="renameGroup">确 定</el-button>
        </span>
      </el-main>
    </el-dialog>
    <el-dialog
      title="删除群组"
      :visible.sync="deleteDialogVisible"
      :destroy-on-close="true"
      :append-to-body="true"
      width="400px"
      :close-on-click-modal="false"
    >
      <el-main v-loading="loading">
        <span class="deleteGroupSpan">确定要删除该群组吗？</span>
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="deleteGroup">确 定</el-button>
        </span>
      </el-main>
    </el-dialog>
  </div>
</template>

<script>
import {
  editGroupsRequest,
  addGroupsRequest,
  deleteGroupsRequest,
} from "@api/repository";
export default {
  props: ["editGroupType", "currenGroupParams"],
  data() {
    return {
      deleteDialogVisible: false,
      renameDialogVisible: false,
      addDialogVisible: false,
      name: "",
      loading: false,
    };
  },
  components: {},
  mounted() {},
  watch: {
    editGroupType(val) {
      if (val.includes("add")) {
        this.addDialogVisible = true;
        this.name = "";
      } else if (val.includes("rename")) {
        this.renameDialogVisible = true;
        this.name = this.currenGroupParams.name;
      } else if (val.includes("delete")) {
        this.deleteDialogVisible = true;
      }
    },
  },
  computed: {
    isEdit() {
      return this.handleChannelType === "edit" ? true : false;
    },
    sucessTitle() {
      return this.isEdit ? "编辑成功" : "添加成功";
    },
    title() {
      return this.isEdit ? "编辑产品渠道" : "添加产品渠道";
    },
  },
  methods: {
    renameGroup() {
      if (this.name.length < 1) {
        this.$message.error("请输入正确的组名");
        return;
      }
      this.loading = true;
      editGroupsRequest(this.currenGroupParams.id, { name: this.name })
        .then(() => {
          this.loading = false;
          this.renameDialogVisible = false;
          this.$emit("refreshGroup");
          this.$message.success("重命名群组成功");
        })
        .catch(() => (this.loading = false));
    },
    deleteGroup() {
      this.loading = true;
      deleteGroupsRequest(this.currenGroupParams.id, { name: this.name })
        .then(() => {
          this.loading = false;
          this.deleteDialogVisible = false;
          this.$emit("refreshGroup");
          this.$message.success("删除群组成功");
        })
        .catch(() => (this.loading = false));
    },
    addGroup() {
      if (this.name.length < 1) {
        this.$message.error("请输入正确的组名");
        return;
      }
      this.loading = true;
      addGroupsRequest({ name: this.name })
        .then(() => {
          this.loading = false;
          this.addDialogVisible = false;
          this.$emit("refreshGroup");
          this.$message.success("添加群组成功");
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 20px !important;
}
.el-main {
  padding: 0px 20px !important;
  .dialog-footer {
    float: right;
  }

  .el-input {
    margin: 15px 0;
  }
  .deleteGroupSpan {
    width: 100%;
    margin-bottom: 10px;
    display: inline-block;
  }
}
</style>