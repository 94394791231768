import Axios from "./index";

// 登录
export const loginRequest = (data) => {
  return Axios.post("api/login", data);
};
// 获取分组列表
export const getGroupsRequest = () => {
  return Axios.get("api/groups");
};

// 添加分组
export const addGroupsRequest = (data) => {
  return Axios.post("api/groups", data);
};

// 修改分组
export const editGroupsRequest = (id, data) => {
  return Axios.put(`api/groups/${id}`, data);
};

// 删除分组
export const deleteGroupsRequest = (id, data) => {
  return Axios.delete(`api/groups/${id}`, data);
};

// 获取设置信息
export const getGroupSetRequest = () => {
  return Axios.get("api/settings");
};

// 修改设置
export const editGroupSetRequest = (name, data) => {
  return Axios.put(`api/settings/${name}`, data);
};

// 获取渠道列表
export const getChannelsListRequest = (id, paginationParams) => {
  return Axios.get(
    `api/channels?group_id=${id}&size=${paginationParams.size}&page=${paginationParams.page}`
  );
};

// 模糊查询渠道列表
export const searchChannelsListRequest = (id, paginationParams, keyword) => {
  return Axios.get("api/channels", {
    params: {
      group_id: id,
      size: paginationParams.size,
      page: paginationParams.page,
      keyword,
    },
  });
};

// 排序渠道列表
export const sortChannelsListRequest = (
  id,
  paginationParams,
  keyword,
  sortParams
) => {
  return Axios.get("api/channels", {
    params: {
      group_id: id,
      size: paginationParams.size,
      page: paginationParams.page,
      keyword,
      sort: sortParams.sort,
      order: sortParams.order,
    },
  });
};

// 添加渠道
export const addChannelRequest = (data) => {
  return Axios.post("api/channels", data);
};

// 渠道详情
export const getChannelDetailRequest = (id) => {
  return Axios.get(`api/channels/${id}`);
};

// 修改渠道
export const editChannelRequest = (id, data) => {
  return Axios.put(`api/channels/${id}`, data);
};

// 删除渠道
export const deleteChannelRequest = (id) => {
  return Axios.delete(`api/channels/${id}`);
};
