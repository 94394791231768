<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  methods: {},
  mounted() {},
  destroyed() {},
};
</script>
<style lang="scss">
#app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  bottom: 0;
  overflow: hidden;
  background-color: #f0f0f0;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

</style>
