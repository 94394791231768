<template>
  <el-container class="home">
    <el-header>
      <div class="left">
        <h2>Starfun 产品渠道管理系统</h2>
        <a target="_blank" href="/Starfun 产品渠道管理系统.pdf">系统说明</a>
      </div>
      <div class="right">
        <el-button type="text" class="global-set" @click="showGlobalSet = true"
          ><i class="el-icon-s-tools"></i> 全局设置</el-button
        >
        <span style="color: #959595">|</span>
        <div>
          <span class="welcome">{{ username }} 您好，</span
          ><el-button type="text" class="log-out" @click="logout"
            >退出登录</el-button
          >
        </div>
      </div>
    </el-header>
    <el-container class="main-box">
      <el-aside width="200px">
        <div class="title">
          <div class="left"><h4>群组</h4></div>
          <div class="right">
            <el-button type="primary" size="small" @click="editGroup('add')"
              >添加</el-button
            >
          </div>
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-vertical-demo group-list"
          @select="handleSelect"
          :collapse="isCollapse"
        >
          <el-menu-item
            v-for="item in groupList"
            :index="item.name"
            :key="item.id"
          >
            <span :title="item.name" class="group-name" slot="title">{{
              item.name
            }}</span>
            <el-dropdown
              v-if="item.id !== ''"
              trigger="click"
              @command="handleCommand"
            >
              <i class="el-icon-more"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="'rename' + '-' + item.id + '-' + item.name"
                  >重命名</el-dropdown-item
                >
                <el-dropdown-item
                  :command="'delete' + '-' + item.id + '-' + item.name"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <router-view></router-view>
    </el-container>
    <edit-group-dialog
      :editGroupType="editGroupType"
      :currenGroupParams="currenGroupParams"
      @refreshGroup="refreshGroup"
    ></edit-group-dialog>
    <global-set-dialog :showGlobalSet.sync="showGlobalSet"></global-set-dialog>
  </el-container>
</template>

<script>
import EditGroupDialog from "@components/EditGroupDialog.vue";
import GlobalSetDialog from "@components/GlobalSetDialog.vue";
import { getGroupsRequest } from "@api/repository";
import { mapMutations, mapState } from "vuex";
export default {
  components: { EditGroupDialog, GlobalSetDialog },
  // 定义属性
  data() {
    return {
      activeIndex: "",
      isCollapse: false,
      editGroupType: "",
      showGlobalSet: false,
      groupList: [],
      currenGroupParams: {},
      currenSelectGroup: {},
      username: "",
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    ...mapState(["user", "changeIndex", "changeId"]),
  },
  watch: {
    changeIndex(val) {
      this.activeIndex = val;
      this.currenSelectGroup.name = val;
      this.currenSelectGroup.id = this.changeId;
      this.$router.push({
        name: "productChannelTable",
        query: this.currenSelectGroup,
      });
    },
  },
  methods: {
    ...mapMutations(["setGroupList"]),
    init() {
      getGroupsRequest().then((response) => {
        const allGroup = {
          id: "",
          name: "全部群组",
        };
        this.setGroupList(response);
        this.groupList = JSON.parse(JSON.stringify(response));
        this.groupList.unshift(allGroup);
        this.activeIndex = this.groupList[0].name;
        this.currenSelectGroup.name = this.groupList[0].name;
        this.currenSelectGroup.id = this.groupList[0].id;
        this.$router.push({
          name: "productChannelTable",
          query: this.currenSelectGroup,
        });
      });
    },
    handleSelect(index) {
      this.activeIndex = index;
      this.currenSelectGroup.name = index;
      this.groupList.forEach((item) => {
        if (item.name === index) {
          this.currenSelectGroup.id = item.id;
        }
      });
      this.$router.push({
        name: "productChannelTable",
        query: this.currenSelectGroup,
      });
    },
    editGroup(type) {
      this.editGroupType = type + Math.random();
    },
    handleCommand(command) {
      this.editGroupType = command.split("-")[0] + Math.random();
      this.currenGroupParams.name = command.split("-")[2];
      this.currenGroupParams.id = command.split("-")[1];
    },
    logout() {
      window.sessionStorage.removeItem("mytoken");
      this.$router.push("/login");
      this.$message.success("退出登录");
    },
    refreshGroup() {
      this.init();
    },
  },
  mounted() {
    this.username = this.user
      ? this.user
      : window.sessionStorage.getItem("username");
    this.init();
  },
};
</script>

<style lang='scss' scoped>
.home {
  width: 100vw;
  height: 100vh;
  .el-header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      display: flex;
      align-items: flex-end;
      a{
        text-decoration: none;
        margin-left: 20px;
        color: #339999;
      }
    }
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 300px;
      .global-set {
        color: #339999;
      }
      .log-out {
        color: #339999;
      }
      .welcome {
        font-size: 14px;
      }
    }
  }
  .main-box {
    background-color: #ffffff;
    overflow: hidden;
    .el-aside {
      display: flex;
      flex-direction: column;
      border-right: solid 1px #e6e6e6;
      .title {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          margin-left: 10px;
        }
        .right {
          margin-right: 10px;
        }
      }
      .el-menu {
        flex: 1;
        border-right: 0;
        .el-menu-item.is-active {
          background: #339999;
          color: #ffffff;
        }
      }
      .group-list {
        .group-name {
          float: left;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
        .el-dropdown {
          float: right;
        }
        .el-menu-item.is-active {
          .el-icon-more {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>