import Vue from "vue";
import Vuex from "vuex";
import devConfigModule from './devConfigModule'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //用户信息
    user: '',
    groupList:[],
    changeIndex:'',
    changeId:''
  },
  getters: {
  },
  mutations: {
   setUser(state,val){
     state.user = val;
   },
   setGroupList(state,val){
    state.groupList = val;
  },
  setChangeIndex(state,val){
    state.changeIndex = val;
  },
  setChangeId(state,val){
    state.changeId = val;
  }
  },
  actions: {
    
  },
  modules: {
    devConfigModule
  },
});
