export default{
  namespaced:true,
  state:{
    devConfigurationIsShow: false
  },
  getters: {
    getDevConfigurationIsShow(state){
      return state.devConfigurationIsShow
    }
  },
  mutations: {
    setDevConfigurationIsShow(state,val){
      state.devConfigurationIsShow = val;
    }
  },
}